//import React, { useState, useEffect } from "react";
import { ValidateResponse } from "../../app/helpers/global";
import { processAddossierDelivery } from "../../app/helpers/addossiers";
import { useParams, useNavigate } from "react-router-dom";
import { AppAddossiersSelectSerialnumber } from "./Select/Serialnumber"

export function AppAddossiersDeliver({ setGlobalMessage }) {

    const { userId } = useParams();
    const navigate = useNavigate();

    const processSelected= (values) => {

        let formData = {
            'userId': userId,
            'products': values.products,
            'addressId': values.addressId,
        }

        processAddossierDelivery(formData).then((res) => {

            const response = ValidateResponse(res.data);

            if (response.status === 'success') {
                response.response.message = response.message;
                response.response.status = response.status;

                navigate('/addossiers/details/' + userId, {
                    replace: true,
                    state: { 'result': response.response }
                });
            } 
            else {
                setGlobalMessage(response.message, response.status);
            }
        }).catch((error) => {
            let response = ValidateResponse(error);
            setGlobalMessage(response.message, 'error');
            return 'error';
        })
    }

    return (
        <AppAddossiersSelectSerialnumber
            userId={userId}
            setGlobalMessage={setGlobalMessage}
            processSelect={processSelected}
            title="Ad uitleen registreren"
        />
    );

}