import React, { useState } from "react";
import { Link } from "react-router-dom";
import { DetailsContent } from "../../../components/Details/Content";
import { AppTransportDetailsArticles } from "./Articles";
import { ContentDownload } from "../../../components/Content/Download";

export function AppTransportDetailsStop({ stop, transportStatus, parents }) {

    let editMode = stop.status === "COMPLETED" || stop.status === "DELETED" ? 'disabled' : 'edit';
    let enableToggle = transportStatus !== 'COMPLETED' && transportStatus !== 'COMPLETED' &&  transportStatus !== 'DELETED';
    let isHidden = enableToggle === true && editMode === 'disabled';

    // check if there are documents to sign
    if (isHidden === true && stop.documents !== undefined) {
        Object.values(stop.documents).forEach((document) => {
            if (document.isSigned === false && document.required === true) {
                isHidden = false;
            }
        });
    }

    const [hidden, setHidden] = useState(isHidden);

    const showHide = () => {
        if (enableToggle === true) {
            setHidden(hidden === false ? true : false);
        }
    }

    const classNames = (classes) => {
        const toBeClasses = Object.keys(classes).map((key) =>
            classes[key] === true ? key : '',
        );
        return toBeClasses.join(' ');
    }
    const checkStatus = (stop) => {

        let status = null;

        if (stop.productIN !== null) {
            stop.productIN.forEach((request) => {
                if (request.processStatus === 'OPEN') {
                    status = 'OPEN';
                }
                if (request.processStatus === 'ERROR' && status !== 'OPEN') {
                    status = 'ERROR';
                }
                if (request.processStatus === 'OK' && (status !== 'OPEN' || status !== 'ERROR')) {
                    status = 'OK';
                }
            });
        }

        if (stop.productOUT !== null) {
            stop.productOUT.forEach((request) => {
                if (request.processStatus === 'OPEN') {
                    status = 'OPEN';
                }
                if (request.processStatus === 'ERROR' && status !== 'OPEN') {
                    status = 'ERROR';
                }
                if (request.processStatus === 'OK' && (status !== 'OPEN' || status !== 'ERROR')) {
                    status = 'OK';
                }
            });
        }

        if (status === null) {
            status = 'OPEN';
        }
        return status;
    }

    let stopColumns = [];
    let stopData = {};
    if (typeof (stop.info) === 'object' && Object.keys(stop.info).length > 0) {
        Object.keys(stop.info).forEach((infoKey) => {
            if (stop.info[infoKey].label !== undefined && stop.info[infoKey].value !== undefined) {
                stopColumns.push({ key: infoKey, label: stop.info[infoKey].label, type: "text" });
                stopData[infoKey] = stop.info[infoKey].value;
            }
        });
    }

    return (
        <div className="component transport-stop-details" style={{ width: '100%' }}>
            <div className={classNames({ 'outline-box': true, 'outline-box--details': hidden })} data-match>
                <div
                    className={
                        classNames({
                            'details-link': true,
                            'details-link--closed': hidden && enableToggle,
                            'details-link--open': (hidden === false && enableToggle),
                            'details-link--icon': true, //checkStatus(stop) !== 'OPEN',
                            'details-link--icon--completed': checkStatus(stop) === 'OK',
                            'details-link--icon--arrow': checkStatus(stop) === 'OPEN',
                            'details-link--icon--error': checkStatus(stop) === 'ERROR'
                        })
                    }
                    onClick={(e) => showHide()}>
                    {stop.stopName ?? stop.stopAddress}
                </div>
                {hidden === false &&

                    <div className="outline-boxes" style={{ paddingTop: "15px" }}>
                        <div className="component">
                            <div className="item-details">
                                {stopColumns.length > 0 &&
                                    <DetailsContent columns={stopColumns} data={stopData} identifier={"stop-details-" + stop.stopId} />
                                }
                            </div>
                        </div>
                        <div className="component">
                            <AppTransportDetailsArticles stop={stop} editMode={editMode} />
                        </div>

                        <div style={{ width: '100%' }}>

                            {stop.documents != null && stop.status === 'COMPLETED' &&

                                <div className="buttons">

                                    {Object.values(stop.documents).map((document) => (

                                        <div key={document.type + (document.contractId ?? 0)}>
                                            {(document.isSigned === false && stop.isInternal !== true) &&
                                                <Link
                                                    to={"/" + parents + "/sign/" + stop.stopId}
                                                    state={{ "documentType": document.type }}
                                                    className={"button button--outline button--white button--readmore justify-content-center" 
                                                        + (document.required ? " error" : "") }>

                                                    <span>{document.typeDisplay} ondertekenen</span>
                                                </Link>

                                            }
                                            {(document.isSigned === true
                                                || (document.required === false)
                                                || (document.isMailed === true && document.type === 'DEPOT-CASE')) &&
                                                <ContentDownload
                                                    documentType={document.type}
                                                    documentId={document.documentNumber}
                                                    display="button"
                                                    fileName={document.fileName}
                                                    label={"Download " + document.typeDisplay}
                                                />
                                            }
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}